<template>
  <div class="container-outer overwrite">
    <main id="full" class="white-bg">
      <section class="dashboard headings-reset">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <h1 class="maintitle">{{ salute }} {{ user.name }},</h1>
              <div class="link-container">
                <div class="row">
                  <div class="col-12 col-lg-4">
                    <router-link to="/dashboard/admin-users" class="user-link">
                      <svg width="70" height="50" viewBox="0 0 70 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <mask id="path-1-inside-1_1217_5527" fill="white">
                        <path d="M42.6777 7.32234C52.4408 17.0855 52.4408 32.9146 42.6777 42.6777C32.9145 52.4408 17.0854 52.4408 7.32234 42.6777C-2.44078 32.9145 -2.44078 17.0854 7.32234 7.32234C17.0855 -2.44078 32.9146 -2.44078 42.6777 7.32234Z"/>
                        </mask>
                        <path d="M44.799 5.20102C43.6274 4.02945 41.7279 4.02945 40.5564 5.20102C39.3848 6.3726 39.3848 8.27209 40.5564 9.44366L44.799 5.20102ZM42.6777 42.6777L40.5564 40.5563L40.5564 40.5564L42.6777 42.6777ZM7.32234 42.6777L9.44367 40.5564L9.44366 40.5564L7.32234 42.6777ZM7.32234 7.32234L9.44366 9.44367L9.44366 9.44366L7.32234 7.32234ZM40.5563 9.44366C41.7279 10.6152 43.6274 10.6152 44.799 9.44367C45.9706 8.2721 45.9706 6.37261 44.799 5.20103L40.5563 9.44366ZM40.5564 9.44366C49.1479 18.0352 49.1479 31.9649 40.5564 40.5563L44.799 44.799C55.7337 33.8644 55.7337 16.1357 44.799 5.20102L40.5564 9.44366ZM40.5564 40.5564C31.9648 49.1479 18.0352 49.1479 9.44367 40.5564L5.20102 44.799C16.1357 55.7337 33.8643 55.7337 44.799 44.799L40.5564 40.5564ZM9.44366 40.5564C0.852109 31.9648 0.852117 18.0352 9.44366 9.44367L5.20103 5.20102C-5.73368 16.1357 -5.73367 33.8643 5.20102 44.799L9.44366 40.5564ZM9.44366 9.44366C18.0352 0.852108 31.9649 0.852117 40.5563 9.44366L44.799 5.20103C33.8644 -5.73368 16.1357 -5.73367 5.20102 5.20102L9.44366 9.44366Z" fill="#25A268" mask="url(#path-1-inside-1_1217_5527)"/>
                        <path d="M31.0104 14.9896C34.3299 18.309 34.3299 23.691 31.0104 27.0104C27.691 30.3299 22.309 30.3299 18.9896 27.0104C15.6701 23.691 15.6701 18.309 18.9896 14.9896C22.309 11.6701 27.691 11.6701 31.0104 14.9896Z" stroke="#25A268" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" class="special-stroke"/>
                        <path d="M42.0015 41.1437C37.317 37.3006 31.4198 35 25.0015 35C18.5843 35 12.688 37.2998 8.00391 41.1437" stroke="#25A268" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" class="special-stroke"/>
                      </svg>
                      <h4 class="title">Toon gebruikers</h4>
                    </router-link>
                  </div>
                  <div class="col-12 col-lg-4">
                    <router-link to="/dashboard/admin-couriers" class="transport-link">
                      <svg width="71" height="50" viewBox="0 0 71 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M48.6008 0L0.668899 0V2.99129H45.6175V27.1235H3.65144L3.65122 22.0147L0.667969 22.0148L0.668912 44.0422H9.38207C9.63498 45.4453 10.3014 46.7886 11.3799 47.8733C14.1994 50.7094 18.7751 50.7081 21.5972 47.8743C22.6756 46.7929 23.3419 45.4538 23.5959 44.0546H47.7426C47.9972 45.4532 48.6629 46.7918 49.7382 47.8733C52.5577 50.7094 57.1334 50.7081 59.9555 47.8743C61.1039 46.7227 61.7848 45.279 61.9984 43.7817H70.668V20.0652L63.6964 3.78494L48.6008 3.78562V0ZM48.6008 30.1148V6.77691L61.7307 6.77632L63.6914 11.355H55.073V26.9618H67.6847V40.7904H61.8044C61.4811 39.6298 60.8647 38.5338 59.9557 37.6209C57.132 34.7853 52.5611 34.7914 49.7399 37.62C48.7582 38.6042 48.1185 39.8015 47.8201 41.0633H23.5164C23.2173 39.802 22.5774 38.6051 21.5974 37.6209C18.7737 34.7853 14.2029 34.7914 11.3816 37.62C10.4032 38.601 9.7644 39.7937 9.46475 41.0509H3.65204L3.65157 30.1148H48.6008ZM58.0563 23.9705V14.3462H64.9723L67.6847 20.6803V23.9705H58.0563ZM13.4921 39.7341C15.1506 38.0726 17.8306 38.0717 19.4863 39.7345C21.1452 41.4003 21.1433 44.0993 19.4864 45.7605C17.8288 47.4251 15.1456 47.424 13.4926 45.7615C11.8352 44.0943 11.8351 41.3954 13.4909 39.7353L13.4921 39.7341ZM51.8504 39.7341C53.5089 38.0726 56.1889 38.0717 57.8446 39.7345C59.5035 41.4003 59.5016 44.0993 57.8447 45.7605C56.1871 47.4251 53.5038 47.424 51.8509 45.7615C50.1934 44.0943 50.1934 41.3954 51.8492 39.7353L51.8504 39.7341Z" fill="#25A268"/>
                        <path d="M12.4044 7.56997H0.668899V10.5613H12.4044V7.56997Z" fill="#25A268"/>
                        <path d="M6.09921 15.1399H0.668899V18.1312H6.09921V15.1399Z" fill="#25A268"/>
                      </svg>
                      <h4 class="title">Toon koeriers</h4>
                    </router-link>
                  </div>
                  <div class="col-12 col-lg-4">
                    <router-link to="/dashboard/admin-transports">
                      <svg width="71" height="50" viewBox="0 0 71 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_1201_29664)">
                        <rect x="2.16797" y="6.5" width="47" height="42" stroke="#25A268" stroke-width="3"/>
                        <path d="M15.668 0L15.668 13" stroke="#25A268" stroke-width="3" stroke-linejoin="round"/>
                        <path d="M35.668 0L35.668 13" stroke="#25A268" stroke-width="3" stroke-linejoin="round"/>
                        <path d="M0.667969 22H50.668" stroke="#25A268" stroke-width="3" stroke-linejoin="round"/>
                        </g>
                        <defs>
                        <clipPath id="clip0_1201_29664">
                        <rect width="70" height="50" fill="white" transform="translate(0.667969)"/>
                        </clipPath>
                        </defs>
                      </svg>
                      <h4 class="title">Toon transporten</h4>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  </div>
</template>

<script>

export default {
  props: ['user'],
  data () {
    return {
    }
  },
  computed: {
    salute () {
      const curDate = new Date();

      if (curDate.getHours() >= 18) {
        return 'Goedeavond';
      } else if (curDate.getHours() >= 12) {
        return 'Goedemiddag';
      } else {
        return 'Goedemorgen';
      }
    }
  }
};

</script>
