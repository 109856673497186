<template>
  <div class="container-outer overwrite">
    <main id="normal">
      <section class="standard-layout settings headings-reset">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <router-link to="/dashboard/admin-index" class="back-link">Naar dashboard</router-link>
              <h1 class="maintitle maintitle-extra-space">Instellingen</h1>
            </div>
            <div class="col-12">
              <p class="intro-paragraph">Accountgegevens kunnen hieronder worden aangepast. </p>
              <form class="inner" ref="formUser" v-on:submit.prevent>
                <h5 class="title">Accountgegevens</h5>
                <div class="row">
                  <div class="col-12 col-lg-6">
                    <div class="form-group">
                      <label for="user-first-name-field" class="label-required">Voornaam</label>
                      <input type="text" id="user-first-name-field" class="form-control" placeholder="Voornaam..." v-model="userEdit.firstName" required>
                    </div>
                  </div>
                  <div class="col-12 col-lg-6">
                    <div class="form-group">
                      <label for="user-last-name-field" class="label-required">Achternaam</label>
                      <input type="text" id="user-last-name-field" class="form-control" placeholder="Achternaam..." v-model="userEdit.lastName" required>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12 col-lg-4">
                    <div class="form-group">
                      <label for="user-email-field" class="label-required">E-mailadres</label>
                      <input type="email" id="user-email-field" class="form-control" placeholder="E-mailadres..." v-model="userEdit.email" required>
                    </div>
                  </div>
                  <div class="col-12 col-lg-4">
                    <div class="form-group">
                      <label for="user-password-field" class="label-required">Nieuw wachtwoord</label>
                      <input type="password" id="user-password-field" class="form-control" placeholder="Wachtwoord..." autocomplete="off" v-model="userEdit.password" required>
                    </div>
                  </div>
                  <div class="col-12 col-lg-4">
                    <div class="form-group">
                      <label for="user-password-check-field" class="label-required">Herhaal wachtwoord</label>
                      <input type="password" id="user-password-check-field" class="form-control" placeholder="Herhaal wachtwoord..." autocomplete="off" v-model="userEdit.passwordCheck" required>
                    </div>
                  </div>
                </div>
                <button type="submit" class="btn-new btn-primary float-right" @click="updateUser">Wijzigingen opslaan</button>
              </form>
            </div>
          </div>
        </div>
      </section>
    </main>
  </div>

  <!-- alert -->

  <div class="alerts alerts-success" role="alert" v-if="showPasswordAlert">
    <div class="image">
      <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="13.5" cy="13.5" r="13.5" fill="white"/>
        <path d="M6.75 12.5945L11.875 17.5512L20.25 9.45117" stroke="#25A268" stroke-width="2.5"/>
      </svg>
    </div>
    <div class="text">
      <p class="bold extra-margin">Wachtwoord succesvol opgeslagen</p>
      <p class="smaller">Je nieuwe wachtwoord is succesvol opgeslagen.</p>
    </div>
  </div>
</template>

<script>

import validateForm from '@/utils/form-validate';

export default {
  props: ['user'],
  data () {
    return {
      showPasswordAlert: false,
      userEdit: {
        'firstName': '',
        'lastName': '',
        'email': '',
        'password': '',
        'passwordCheck': ''
      }
    }
  },
  methods: {
    getUser () {

      fetch('/api/users/' + this.user['id'])
        .then(response => response.ok ? response.json() : this.userEdit)
        .then(data => {
          this.userEdit = data;
        })
    },
    updateUser () {

      fetch('/api/users/' + this.user['id'], {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(this.userEdit)
      })
        .then(response => {

          if (response.ok) {

            if (this.userEdit.password) {
              this.showPasswordAlert = true;
              this.userEdit.password = '';
              this.userEdit.passwordCheck = '';

              setTimeout(() => {
                this.showPasswordAlert = false;
              }, 3000);
            }
          }
        })
    }
  },
  watch: {
    userEdit: {
      deep: true,
      handler: function () {
        validateForm(this.$refs['formUser'], !this.userEdit.password || this.userEdit.password === this.userEdit.passwordCheck);
      }
    }
  },
  created () {
    this.getUser();
  }
}

</script>
