<template>
  <div class="container-outer overwrite">
    <main id="normal">
      <section class="standard-layout transport-history transport-history-admin headings-reset">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <router-link to="/dashboard/admin-index" class="back-link">Naar dashboard</router-link>
              <h1 class="maintitle">Gebruikers</h1>
              <button type="button" class="btn btn-primary d-none d-lg-flex btn-new" data-toggle="modal" data-target="#addCustomerModal" @click="initCustomerAdd">
                <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M9 0.5V9.7902M9 18.5V9.7902M9 9.7902H0H18" stroke="white" stroke-width="2"/>
                </svg>
              </button>
              <div class="form-group form-group-extra-space form-group-border-bottom d-none d-lg-block">
                <div class="icon-container icon-container-left icon-container-right search-container" :class="{ 'search-active': filter.search }">
                  <img src="@/assets/img/icon-search.svg" alt="search">
                  <input type="text" class="form-control" placeholder="Zoeken..." v-model="filter.search">
                  <img class="search-img" src="@/assets/img/search-remove.svg" alt="remove" v-if="filter.search" @click="filter.search = ''">
                </div>
              </div>
              <div class="form-group form-group-extra-space d-none d-lg-block">
                <select class="form-control select-form-group" v-model="filter.type">
                  <option value="">Alle gebruikers</option>
                  <option value="private">Particuliere gebruikers</option>
                  <option value="business">Zakelijke gebruikers</option>
                </select>
              </div>
            </div>
            <div class="col-12 d-lg-none">
              <button type="button" class="btn btn-primary d-lg-none btn-new" data-toggle="modal" data-target="#addCustomerModal" @click="initCustomerAdd">Gebruiker aanmaken</button>
              <div class="form-group form-group-extra-space">
                <select class="form-control select-form-group" v-model="filter.type">
                  <option value="">Alle gebruikers</option>
                  <option value="private">Particuliere gebruikers</option>
                  <option value="business">Zakelijke gebruikers</option>
                </select>
              </div>
              <div class="form-group form-group-extra-space form-group-border-bottom search-active">
                <div class="icon-container icon-container-left icon-container-right search-container">
                  <img src="@/assets/img/icon-search.svg" alt="search">
                  <input type="text" class="form-control" placeholder="Zoeken..." v-model="filter.search">
                  <img class="search-img" src="@/assets/img/search-remove.svg" alt="remove" v-if="filter.search" @click="filter.search = ''">
                </div>
              </div>
            </div>
            <div class="col-12" v-if="customers.length">
              <div class="table-responsive">
                <table class="table table-striped table-custom">
                  <thead>
                    <tr>
                      <th>Voornaam</th>
                      <th>Achternaam</th>
                      <th>Bedrijfsnaam</th>
                      <th>Transporthistorie</th>
                      <th>Gebruiker sinds</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="customer in customers" :key="customer.id">
                      <td>{{ customer.customer.firstName }}</td>
                      <td>{{ customer.customer.lastName }}</td>
                      <td :class="{ 'empty': !customer.customer.companyName }">{{ customer.customer.companyName || '&mdash;' }}</td>
                      <td>{{ customer.orders[0]['count'] + (customer.orders[0]['count'] === 1 ? ' transport' : ' transporten') }}</td>
                      <td>{{ formatDateFull(customer.created) }}</td>
                      <td class="with-btn"><button class="btn btn-black btn-small" data-toggle="modal" data-target="#customerDetailModal" @click="customerEdit = JSON.parse(JSON.stringify(customer))">Details</button></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>

    <!-- add customer modal -->

    <div class="modal fade" id="addCustomerModal" tabindex="-1" role="dialog" aria-labelledby="addCustomerModal" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <form class="modal-content" ref="formCustomerAdd" v-on:submit.prevent>
          <div class="modal-header">
            <h3 class="modal-title" id="addCustomerModalTitle">Gebruiker aanmaken</h3>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M17 1L9 9M1 17L9 9M9 9L1 1L17 17" stroke="#B8BBC2"/>
              </svg>
            </button>
          </div>
          <div class="info-alert info-alert-header">
            <div class="image">
              <img src="~@/assets/img/icon-info.svg" alt="info">
            </div>
            <div class="text">
              <p>Na het aanmaken van een gebruiker wordt er een uitnodiging per e-mail verzonden.</p>
            </div>
          </div>
          <div class="modal-body">
            <div class="inner">
              <h4 class="title">Accountgegevens</h4>
              <div class="row">
                <div class="col-12 col-lg-6">
                  <div class="form-group">
                    <label for="user-first-name-field-add" class="label-required">Voornaam</label>
                    <input id="user-first-name-field-add" type="text" class="form-control" placeholder="Voornaam..." v-model="customerAdd.user.firstName" required>
                  </div>
                </div>
                <div class="col-12 col-lg-6">
                  <div class="form-group">
                    <label for="user-last-name-field-add" class="label-required">Achternaam</label>
                    <input id="user-last-name-field-add" type="text" class="form-control" placeholder="Achternaam..." v-model="customerAdd.user.lastName" required>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <div class="form-group">
                    <label for="user-email-field-add" class="label-required">E-mailadres</label>
                    <input id="user-email-field-add" type="email" class="form-control" placeholder="E-mailadres..." v-model="customerAdd.user.email" required>
                  </div>
                </div>
              </div>
            </div>
            <div class="inner">
              <h4 class="title">Contactgegevens</h4>
              <div class="row">
                <div class="col-12">
                  <div class="form-group normal-radio-group">
                    <div class="form-check-outer">
                      <div class="form-check">
                        <input type="radio" id="customer-type-private-radio-add" name="customerType" class="form-check-input" value="private" v-model="customerAdd.customer.type">
                        <label for="customer-type-private-radio-add" class="form-check-label">Particulier transport</label>
                      </div>
                      <div class="form-check">
                        <input type="radio" id="customer-type-business-radio-add" name="customerType" class="form-check-input" value="business" v-model="customerAdd.customer.type">
                        <label for="customer-type-business-radio-add" class="form-check-label">Zakelijk transport</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-lg-6">
                  <div class="form-group">
                    <label for="customer-company-name-field-add">Bedrijfsnaam</label>
                    <input id="customer-company-name-field-add" type="text" class="form-control" placeholder="Bedrijfsnaam..." v-model="customerAdd.customer.companyName">
                  </div>
                </div>
                <div class="col-12 col-lg-6">
                  <div class="form-group">
                    <label for="customer-coc-field-add">KvK-nummer</label>
                    <input id="customer-coc-field-add" type="text" class="form-control" placeholder="KvK-nummer..." v-model="customerAdd.customer.coc">
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-lg-6">
                  <div class="form-group">
                    <label for="customer-first-name-field-add">Voornaam</label>
                    <input id="customer-first-name-field-add" type="text" class="form-control" placeholder="Voornaam..." v-model="customerAdd.customer.firstName">
                  </div>
                </div>
                <div class="col-12 col-lg-6">
                  <div class="form-group">
                    <label for="customer-last-name-field-add">Achternaam</label>
                    <input id="customer-last-name-field-add" type="text" class="form-control" placeholder="Achternaam..." v-model="customerAdd.customer.lastName">
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-lg-6">
                  <div class="form-group">
                    <label for="customer-email-field-add">E-mailadres</label>
                    <input id="customer-email-field-add" type="text" class="form-control" placeholder="E-mailadres..." v-model="customerAdd.customer.email">
                  </div>
                </div>
                <div class="col-12 col-lg-6">
                  <div class="form-group">
                    <label for="customer-phone-field-add">Telefoonnummer</label>
                    <input id="customer-phone-field-add" type="text" class="form-control" placeholder="Telefoonnummer..." v-model="customerAdd.customer.phone">
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-lg-6">
                  <div class="form-group">
                    <label for="customer-postal-code-field-add" class="label-required">Postcode</label>
                    <input id="customer-postal-code-field-add" type="text" class="form-control" placeholder="Postcode..." v-model="customerAdd.customer.postalCode" @change="setAddress('', customerAdd.customer)" required>
                  </div>
                </div>
                <div class="col-12 col-lg-3">
                  <div class="form-group">
                    <label for="customer-house-number-field-add" class="label-required">Huisnr.</label>
                    <input id="customer-house-number-field-add" type="text" class="form-control" placeholder="Huisnr..." v-model="customerAdd.customer.houseNumber" @change="setAddress('', customerAdd.customer)" required>
                  </div>
                </div>
                <div class="col-12 col-lg-3">
                  <div class="form-group">
                    <label for="customer-house-number-addition-field-add">Toev.</label>
                    <input id="customer-house-number-addition-field-add" type="text" class="form-control" placeholder="Toev..." v-model="customerAdd.customer.houseNumberAddition" @change="setAddress('', customerAdd.customer)">
                  </div>
                </div>
              </div>
              <div class="result result-address" v-if="customerAdd.customer.address">
                <img src="~@/assets/img/icon-marker.svg" alt="marker">
                <p class="smaller">{{ customerAdd.customer.address }}</p>
              </div>
            </div>
            <button type="submit" class="btn-new btn-primary float-right" data-dismiss="modal" @click="addCustomer">Gebruiker aanmaken</button>
          </div>
        </form>
      </div>
    </div>

    <!-- edit customer modal  -->

    <div class="modal fade" id="customerDetailModal" tabindex="-1" role="dialog" aria-labelledby="customerDetailModal" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <form class="modal-content" ref="formCustomerEdit" v-on:submit.prevent>
          <div class="modal-header">
            <h3 class="modal-title" id="customerDetailModalTitle">{{ customerEdit.customer.fullName }}</h3>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M17 1L9 9M1 17L9 9M9 9L1 1L17 17" stroke="#B8BBC2"/>
              </svg>
            </button>
          </div>
          <div class="modal-body" v-if="customerEdit">
            <div class="overview-content-container">
              <div class="row">
                <div class="col-12 col-lg-6">
                  <div class="overview-content-outer">
                    <div class="icon">
                      <svg width="45" height="44" viewBox="0 0 45 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="0.5" width="44" height="44" rx="22" fill="#25A268"/>
                        <path d="M11.4219 13.6845L16.014 9.07812L17.2321 10.3L14.7162 12.8237H22.555C24.7358 12.824 26.8672 13.4729 28.6795 14.6876C30.4921 15.9025 31.904 17.6287 32.7375 19.6472C33.571 21.6656 33.789 23.8863 33.3642 26.0287C32.9393 28.1711 31.8906 30.1398 30.3495 31.6856C28.8085 33.2314 26.8443 34.2849 24.705 34.7117C22.5657 35.1386 20.3483 34.9194 18.3335 34.0823C16.3187 33.2452 14.5977 31.828 13.3873 30.0109C12.1769 28.1938 11.5313 26.0582 11.5313 23.874V23.6304H13.26V23.874C13.26 25.7203 13.8058 27.5247 14.8278 29.0589C15.8497 30.593 17.3016 31.7878 18.999 32.4931C20.6965 33.1984 22.5639 33.3828 24.3654 33.0234C26.167 32.6639 27.8227 31.7765 29.1228 30.4723C30.423 29.1681 31.3091 27.5057 31.6682 25.695C32.0273 23.8843 31.8429 22.0075 31.1387 20.3022C30.4346 18.597 29.2426 17.1405 27.7143 16.1162C26.1866 15.0922 24.3912 14.5459 22.555 14.5456H14.7166L17.2321 17.069L16.014 18.2909L11.4219 13.6845Z" fill="white"/>
                        <path d="M20.6627 25.6893V19.5971H22.3915V23.9674H26.6112V25.6893H20.6627Z" fill="white"/>
                      </svg>
                    </div>
                    <div class="text">
                      <p class="smaller">Transporthistorie</p>
                      <p class="unique-font">{{ customerEdit.orders[0]['count'] + ' ' + (customerEdit.orders[0]['count'] === 1 ? 'transport' : 'transporten') }}</p>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-lg-6">
                  <div class="overview-content-outer">
                    <div class="icon">
                      <svg width="45" height="44" viewBox="0 0 45 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="0.5" width="44" height="44" rx="22" fill="#25A268"/>
                        <rect x="12.0469" y="13.6367" width="20.9" height="18.8112" stroke="white" stroke-width="1.5"/>
                        <path d="M18.3164 11.5508L18.3164 16.9851" stroke="white" stroke-width="1.5" stroke-linejoin="round"/>
                        <path d="M26.6797 11.5508L26.6797 16.9851" stroke="white" stroke-width="1.5" stroke-linejoin="round"/>
                        <path d="M12.0469 20.7461H32.9469" stroke="white" stroke-width="1.5" stroke-linejoin="round"/>
                      </svg>
                    </div>
                    <div class="text">
                      <p class="smaller">Gebruiker sinds</p>
                      <p class="unique-font">{{ formatDateFull(customerEdit.created) }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="inner">
              <h4 class="title">Accountgegevens</h4>
              <div class="row">
                <div class="col-12 col-lg-6">
                  <div class="form-group">
                    <label for="user-first-name-field-edit" class="label-required">Voornaam</label>
                    <input id="user-first-name-field-edit" type="text" class="form-control" placeholder="Voornaam..." v-model="customerEdit.user.firstName" required>
                  </div>
                </div>
                <div class="col-12 col-lg-6">
                  <div class="form-group">
                    <label for="user-last-name-field-edit" class="label-required">Achternaam</label>
                    <input id="user-last-name-field-edit" type="text" class="form-control" placeholder="Achternaam..." v-model="customerEdit.user.lastName" required>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <div class="form-group">
                    <label for="user-email-field-edit" class="label-required">E-mailadres</label>
                    <input id="user-email-field-edit" type="email" class="form-control" placeholder="E-mailadres..." v-model="customerEdit.user.email" required>
                  </div>
                </div>
              </div>
              <a href="#" class="reset-link" data-dismiss="modal" @click="recoverPassword" :disabled="!customerEdit.user.email">Wachtwoord resetten</a>
            </div>
            <div class="inner">
              <h4 class="title">Contactgegevens</h4>
              <div class="row">
                <div class="col-12">
                  <div class="form-group normal-radio-group">
                    <div class="form-check-outer">
                      <div class="form-check">
                        <input type="radio" id="customer-type-private-radio-edit" name="customerType" class="form-check-input" value="private" v-model="customerEdit.customer.type">
                        <label for="customer-type-private-radio-edit" class="form-check-label">Particulier transport</label>
                      </div>
                      <div class="form-check">
                        <input type="radio" id="customer-type-business-radio-edit" name="customerType" class="form-check-input" value="business" v-model="customerEdit.customer.type">
                        <label for="customer-type-business-radio-edit" class="form-check-label">Zakelijk transport</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-lg-6">
                  <div class="form-group">
                    <label for="customer-company-name-field-edit">Bedrijfsnaam</label>
                    <input id="customer-company-name-field-edit" type="text" class="form-control" placeholder="Bedrijfsnaam..." v-model="customerEdit.customer.companyName">
                  </div>
                </div>
                <div class="col-12 col-lg-6">
                  <div class="form-group">
                    <label for="customer-coc-field-edit">KvK-nummer</label>
                    <input id="customer-coc-field-edit" type="text" class="form-control" placeholder="KvK-nummer..." v-model="customerEdit.customer.coc">
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-lg-6">
                  <div class="form-group">
                    <label for="customer-first-name-field-edit">Voornaam</label>
                    <input id="customer-first-name-field-edit" type="text" class="form-control" placeholder="Voornaam..." v-model="customerEdit.customer.firstName">
                  </div>
                </div>
                <div class="col-12 col-lg-6">
                  <div class="form-group">
                    <label for="customer-last-name-field-edit">Achternaam</label>
                    <input id="customer-last-name-field-edit" type="text" class="form-control" placeholder="Achternaam..." v-model="customerEdit.customer.lastName">
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-lg-6">
                  <div class="form-group">
                    <label for="customer-email-field-edit">E-mailadres</label>
                    <input id="customer-email-field-edit" type="text" class="form-control" placeholder="E-mailadres..." v-model="customerEdit.customer.email">
                  </div>
                </div>
                <div class="col-12 col-lg-6">
                  <div class="form-group">
                    <label for="customer-phone-field-edit">Telefoonnummer</label>
                    <input id="customer-phone-field-edit" type="text" class="form-control" placeholder="Telefoonnummer..." v-model="customerEdit.customer.phone">
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-lg-6">
                  <div class="form-group">
                    <label for="customer-postal-code-field-edit" class="label-required">Postcode</label>
                    <input id="customer-postal-code-field-edit" type="text" class="form-control" placeholder="Postcode..." v-model="customerEdit.customer.postalCode" @change="setAddress()" required>
                  </div>
                </div>
                <div class="col-12 col-lg-3">
                  <div class="form-group">
                    <label for="customer-house-number-field-edit" class="label-required">Huisnr.</label>
                    <input id="customer-house-number-field-edit" type="text" class="form-control" placeholder="Huisnr..." v-model="customerEdit.customer.houseNumber" @change="setAddress()" required>
                  </div>
                </div>
                <div class="col-12 col-lg-3">
                  <div class="form-group">
                    <label for="customer-house-number-addition-field-edit">Toev.</label>
                    <input id="customer-house-number-addition-field-edit" type="text" class="form-control" placeholder="Toev..." v-model="customerEdit.customer.houseNumberAddition" @change="setAddress()">
                  </div>
                </div>
              </div>
              <div class="result result-address" v-if="customerEdit.customer.address">
                <img src="~@/assets/img/icon-marker.svg" alt="marker">
                <p class="smaller">{{ customerEdit.customer.address }}</p>
              </div>
            </div>
            <div class="inner">
              <h4 class="title">Ophaaladres</h4>
              <div class="row">
                <div class="col-12 col-lg-6">
                  <div class="form-group">
                    <label for="pickup-postal-code-field">Postcode</label>
                    <input type="text" id="pickup-postal-code-field" class="form-control" placeholder="Postcode..." v-model="customerEdit.customer.pickupPostalCode" @change="setAddress('pickup')">
                  </div>
                </div>
                <div class="col-6 col-lg-3">
                  <div class="form-group">
                    <label for="pickup-house-number-field">Huisnr.</label>
                    <input type="text" id="pickup-house-number-field" class="form-control" placeholder="Huisnr..." v-model="customerEdit.customer.pickupHouseNumber" @change="setAddress('pickup')">
                  </div>
                </div>
                <div class="col-6 col-lg-3">
                  <div class="form-group">
                    <label for="pickup-house-number-addition-field">Toev.</label>
                    <input type="text" id="pickup-house-number-addition-field" class="form-control" placeholder="Toev..." v-model="customerEdit.customer.pickupHouseNumberAddition" @change="setAddress('pickup')">
                  </div>
                </div>
              </div>
              <div class="result result-address" v-if="customerEdit.customer.pickupAddress">
                <img src="~@/assets/img/icon-marker.svg" alt="marker">
                <p class="smaller">{{ customerEdit.customer.pickupAddress }}</p>
              </div>
              <div class="form-group form-check special-check special-check-no-icon simple-checks">
                <input type="checkbox" id="pickup-phone-check" name="pickupPhoneCheck" class="form-check-input" @click="customerEdit.customer.pickupPhoneCheck = !customerEdit.customer.pickupPhoneCheck" v-model="customerEdit.customer.pickupPhoneCheck">
                <label for="pickup-phone-check" class="form-check-label">Telefoonnummer van contactpersoon ter plaatse wijkt af van die van de aanvrager</label>
              </div>
              <div class="form-group last-form-group" v-if="customerEdit.customer.pickupPhoneCheck">
                <label for="pickup-contact-phone-field">Telefoonnummer contactpersoon</label>
                <input type="text" pattern="^(\d{10})$" id="pickup-contact-phone-field" class="form-control" placeholder="Telefoonnummer..." v-model="customerEdit.customer.pickupPhone">
              </div>
            </div>
            <div class="inner">
              <h4 class="title">Afleveradres</h4>
              <div class="row">
                <div class="col-12 col-lg-6">
                  <div class="form-group">
                    <label for="delivery-postal-code-field">Postcode</label>
                    <input type="text" id="delivery-postal-code-field" class="form-control" placeholder="Postcode..." v-model="customerEdit.customer.deliveryPostalCode" @change="setAddress('delivery')">
                  </div>
                </div>
                <div class="col-6 col-lg-3">
                  <div class="form-group">
                    <label for="delivery-house-number-field">Huisnr.</label>
                    <input type="text" id="delivery-house-number-field" class="form-control" placeholder="Nr..." v-model="customerEdit.customer.deliveryHouseNumber" @change="setAddress('delivery')">
                  </div>
                </div>
                <div class="col-6 col-lg-3">
                  <div class="form-group">
                    <label for="delivery-house-number-addition-field">Toev.</label>
                    <input type="text" id="delivery-house-number-addition-field" class="form-control" placeholder="Toev..." v-model="customerEdit.customer.deliveryHouseNumberAddition" @change="setAddress('delivery')">
                  </div>
                </div>
              </div>
              <div class="result result-address" v-if="customerEdit.customer.deliveryAddress">
                <img src="~@/assets/img/icon-marker.svg" alt="Marker">
                <p class="smaller">{{ customerEdit.customer.deliveryAddress }}</p>
              </div>
              <div class="form-group form-check special-check special-check-no-icon simple-checks">
                <input type="checkbox" id="delivery-phone-check" name="deliveryPhoneCheck" class="form-check-input" @click="customerEdit.customer.customer.deliveryPhoneCheck = !customerEdit.customer.deliveryPhoneCheck" v-model="customerEdit.customer.deliveryPhoneCheck">
                <label for="delivery-phone-check" class="form-check-label">Telefoonnummer van contactpersoon ter plaatse wijkt af van die van de aanvrager</label>
              </div>
              <div class="form-group last-form-group" v-if="customerEdit.customer.deliveryPhoneCheck">
                <label for="delivery-contact-phone-field">Telefoonnummer contactpersoon</label>
                <input type="text" pattern="^(\d{10})$" id="delivery-contact-phone-field" class="form-control" placeholder="Telefoonnummer..." v-model="customerEdit.customer.deliveryPhone">
              </div>
            </div>
            <button type="submit" class="btn-new btn-primary float-right" data-dismiss="modal" @click="updateCustomer">Wijzigingen opslaan</button>
          </div>
        </form>
      </div>
    </div>
  </div>

  <!-- alert -->

  <div class="alerts alerts-success" role="alert" v-if="showPasswordAlert">
    <div class="image">
      <img src="~@/assets/img/check-white-round.svg" width="27" height="27" alt="check">
    </div>
    <div class="text">
      <p class="bold extra-margin">Wachtwoord opnieuw ingesteld</p>
      <p class="smaller">Er is een e-mail gestuurd naar {{ customerEdit.email }} met instructies.</p>
    </div>
  </div>
</template>

<script>

import { formatDateFull } from '@/utils/format';
import validateForm from '@/utils/form-validate';

export default {
  props: ['user'],
  data () {
    return {
      loading: false,
      showPasswordAlert: false,
      customers: [],
      customerDefault: {
        'user': {
          'id': '',
          'firstName': '',
          'lastName': '',
          'email': ''
        },
        'customer': {
          'type': 'private',
          'companyName': '',
          'coc': '',
          'firstName': '',
          'lastName': '',
          'fullName': '',
          'email': '',
          'phone': '',
          'pickupPostalCode': '',
          'pickupHouseNumber': '',
          'pickupHouseNumberAddition': '',
          'pickupStreet': '',
          'pickupCity': '',
          'pickupAddress': '',
          'pickupPhone': '',
          'pickupPhoneCheck': false,
          'deliveryPostalCode': '',
          'deliveryHouseNumber': '',
          'deliveryHouseNumberAddition': '',
          'deliveryStreet': '',
          'deliveryCity': '',
          'deliveryAddress': '',
          'deliveryPhone': '',
          'deliveryPhoneCheck': false
        },
        'orders': [
          {
            'count': 0
          }
        ],
        'created': ''
      },
      customerAdd: {},
      customerEdit: {},
      filter: {
        'type': '',
        'search': ''
      }
    }
  },
  methods: {
    formatDateFull,
    initCustomerAdd () {
      this.customerAdd = JSON.parse(JSON.stringify(this.customerDefault));
    },
    setAddress(type = '', customerObj = null) {
      let validAddress = false;
      const
        postalCodeKeySuffix = type ? 'PostalCode' : 'postalCode',
        houseNumberKeySuffix = type ? 'HouseNumber' : 'houseNumber',
        houseNumberAdditionKeySuffix = type ? 'HouseNumberAddition' : 'houseNumberAddition',
        streetKeySuffix = type ? 'Street' : 'street',
        cityKeySuffix = type ? 'City' : 'city',
        addressKeySuffix = type ? 'Address' : 'address';

      if (!customerObj) {
        customerObj = this.customerEdit.customer;
      }

      if (customerObj[type + postalCodeKeySuffix]
        && customerObj[type + houseNumberKeySuffix]
      ) {
        customerObj[type + postalCodeKeySuffix] = customerObj[type + postalCodeKeySuffix].toUpperCase().replace(/\s/g, '');

        fetch('/api/orders/validate-postal-code/' + customerObj[type + postalCodeKeySuffix] + '/' + customerObj[type + houseNumberKeySuffix])
          .then(response => response.ok ? response.json() : [])
          .then(data => {

            if (data['valid']) {
              validAddress = true;

              customerObj[type + streetKeySuffix] = data['street'];
              customerObj[type + cityKeySuffix] = data['city'];
              customerObj[type + addressKeySuffix] =
                customerObj[type + streetKeySuffix]
                + ' '
                + customerObj[type + houseNumberKeySuffix]
                + customerObj[type + houseNumberAdditionKeySuffix]
                + ' in ' + customerObj[type + cityKeySuffix];
            }
          })
      }

      if (!validAddress) {
        customerObj[type + streetKeySuffix] = '';
        customerObj[type + cityKeySuffix] = '';
        customerObj[type + addressKeySuffix] = '';
      }
    },
    getCustomers () {

      if (this.loading) {
        return false;
      }

      this.loading = true;

      fetch('/api/customers?type=' + this.filter.type + '&search=' + encodeURIComponent(this.filter.search))
        .then(response => response.ok ? response.json() : [])
        .then(data => {
          this.customers = data;
          this.loading = false;
        })
    },
    addCustomer () {

      fetch('/api/customers', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(this.customerAdd)
      })
        .then(response => {

          if (response.ok) {
            this.getCustomers();
          }
        })
    },
    updateCustomer () {

      fetch('/api/customers/' + this.customerEdit.id, {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(this.customerEdit)
      })
        .then(response => {

          if (response.ok) {
            this.getCustomers();
          }
        })
    },
    recoverPassword () {

      fetch('/api/password-recover', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 'email': this.customerEdit.user.email })
      })
        .then(response => {

          if (response.ok) {
            this.showPasswordAlert = true;

            setTimeout(() => {
              this.showPasswordAlert = false;
            }, 3000);
          }
        })
    }
  },
  watch: {
    filter: {
      deep: true,
      handler: function () {
        this.getCustomers();
      }
    },
    customerAdd: {
      deep: true,
      handler: function () {
        validateForm(this.$refs['formCustomerAdd']);
      }
    },
    customerEdit: {
      deep: true,
      handler: function () {
        validateForm(this.$refs['formCustomerEdit']);
      }
    }
  },
  created () {
    this.customerAdd = JSON.parse(JSON.stringify(this.customerDefault));
    this.customerEdit = JSON.parse(JSON.stringify(this.customerDefault));

    this.getCustomers();
  }
}

</script>
