<template>
  <div class="container-outer overwrite">
    <main id="normal">
      <section class="standard-layout transport-history transport-history-admin headings-reset">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <router-link to="/dashboard/admin-index" class="back-link">Naar dashboard</router-link>
              <h1 class="maintitle">Koeriers</h1>
              <button class="btn btn-primary d-none d-lg-flex btn-new" data-toggle="modal" data-target="#addCourierModal" @click="initCourierAdd">
                <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M9 0.5V9.7902M9 18.5V9.7902M9 9.7902H0H18" stroke="white" stroke-width="2"/>
                </svg>
              </button>
              <div class="form-group form-group-extra-space form-group-border-bottom d-none d-lg-block">
                <div class="icon-container icon-container-left icon-container-right search-container" :class="{ 'search-active': filter.search }">
                  <img src="@/assets/img/icon-search.svg" alt="search">
                  <input type="text" class="form-control" placeholder="Zoeken..." v-model="filter.search">
                  <img class="search-img" src="@/assets/img/search-remove.svg" alt="remove" v-if="filter.search" @click="filter.search = ''">
                </div>
              </div>
              <div class="form-group form-group-extra-space d-none d-lg-block">
                <select class="form-control select-form-group" v-model="filter.status">
                  <option value="">Alle koeriers tonen</option>
                  <option value="new">Nieuwe koeriers</option>
                  <option value="active">Actieve koeriers</option>
                  <option value="inactive">Inactieve koeriers</option>
                </select>
              </div>
            </div>
            <div class="col-12 d-lg-none">
              <button class="btn btn-primary d-lg-none btn-new" data-toggle="modal" data-target="#addCourierModal" data-dismiss="modal" @click="initCourierAdd">Koerier aanmaken</button>
              <div class="form-group form-group-extra-space">
                <select class="form-control select-form-group" v-model="filter.status">
                  <option value="">Alle koeriers tonen</option>
                  <option value="new">Nieuwe koeriers</option>
                  <option value="active">Actieve koeriers</option>
                  <option value="inactive">Inactieve koeriers</option>
                </select>
              </div>
              <div class="form-group form-group-extra-space form-group-border-bottom">
                <div class="icon-container icon-container-left icon-container-right search-container">
                  <img src="@/assets/img/icon-search.svg" alt="search">
                  <input type="text" class="form-control" placeholder="Zoeken..." v-model="filter.search">
                  <img class="search-img" src="@/assets/img/search-remove.svg" alt="remove" v-if="filter.search" @click="filter.search = ''">
                </div>
              </div>
            </div>
            <div class="col-12" v-if="couriers.length">
              <div class="table-responsive">
                <table class="table table-striped table-custom">
                  <thead>
                    <tr>
                      <th>Bedrijfsnaam</th>
                      <th>Voornaam</th>
                      <th>Achternaam</th>
                      <th>Transporthistorie</th>
                      <th>Status</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="courier in couriers" :key="courier.id">
                      <td>{{ courier.courier.companyName }}</td>
                      <td>{{ courier.courier.firstName }}</td>
                      <td>{{ courier.courier.lastName }}</td>
                      <td :class="{ 'empty': !courier.transports[0]['count'] }">{{ courier.transports[0]['count'] + (courier.transports[0]['count'] === 1 ? ' transport' : ' transporten') }}</td>
                      <td>
                        <div class="label label-new" v-if="courier.courier.status === 'new'">Nieuw</div>
                        <div class="label label-green" v-if="courier.courier.status === 'active'">Actief</div>
                        <div class="label label-gray" v-if="courier.courier.status === 'inactive'">Inactief</div>
                      </td>
                      <td class="with-btn"><button class="btn btn-black btn-small" data-toggle="modal" data-target="#courierDetailModal" @click="initCourierEdit(courier)">{{ courier.courier.status === 'active' ? 'Toon details' : 'Activeren' }}</button></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>

    <!-- add courier modal -->

    <div class="modal fade" id="addCourierModal" tabindex="-1" role="dialog" aria-labelledby="addCourierModal" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <form class="modal-content" ref="formCourierAdd" v-on:submit.prevent>
          <div class="modal-header">
            <h3 class="modal-title" id="addCourierModalTitle">Koerier aanmaken</h3>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M17 1L9 9M1 17L9 9M9 9L1 1L17 17" stroke="#B8BBC2"/>
              </svg>
            </button>
          </div>
          <div class="info-alert info-alert-header">
            <div class="image">
              <img src="~@/assets/img/icon-info.svg" alt="info">
            </div>
            <div class="text">
              <p>Na het aanmaken van een gebruiker wordt er een uitnodiging per e-mail verzonden.</p>
            </div>
          </div>
          <div class="modal-body">
            <div class="inner">
              <h4 class="title">Bedrijfsgegevens</h4>
              <div class="row">
                <div class="col-12 col-lg-6">
                  <div class="form-group">
                    <label for="courier-company-name-field-add" class="label-required">Bedrijfsnaam</label>
                    <input id="courier-company-name-field-add" type="text" class="form-control" placeholder="Bedrijfsnaam..." v-model="courierAdd.courier.companyName" required>
                  </div>
                </div>
                <div class="col-12 col-lg-6">
                  <div class="form-group">
                    <label for="courier-coc-field-add" class="label-required">KvK-nummer</label>
                    <input id="courier-coc-field-add" type="text" class="form-control" placeholder="KvK-nummer" v-model="courierAdd.courier.coc" required>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-lg-6">
                  <div class="form-group">
                    <label for="courier-postal-code-field-add" class="label-required">Postcode</label>
                    <input id="courier-postal-code-field-add" type="text" class="form-control" placeholder="Postcode..." v-model="courierAdd.courier.postalCode" @change="setAddress(courierAdd.courier)" required>
                  </div>
                </div>
                <div class="col-12 col-lg-3">
                  <div class="form-group">
                    <label for="courier-house-number-field-add" class="label-required">Huisnr.</label>
                    <input id="courier-house-number-field-add" type="text" class="form-control" placeholder="Huisnr..." v-model="courierAdd.courier.houseNumber" @change="setAddress(courierAdd.courier)" required>
                  </div>
                </div>
                <div class="col-12 col-lg-3">
                  <div class="form-group">
                    <label for="courier-house-number-addition-field-add">Toev.</label>
                    <input id="courier-house-number-addition-field-add" type="text" class="form-control" placeholder="Toev..." v-model="courierAdd.courier.houseNumberAddition">
                  </div>
                </div>
              </div>
              <div class="result result-address" v-if="courierAdd.courier.address">
                <img src="~@/assets/img/icon-marker.svg" alt="marker">
                <p class="smaller">{{ courierAdd.courier.address }}</p>
              </div>
            </div>
            <div class="inner">
              <h4 class="title">Contactpersoon</h4>
              <div class="row">
                <div class="col-12 col-lg-6">
                  <div class="form-group">
                    <label for="courier-first-name-field-add">Voornaam</label>
                    <input id="courier-first-name-field-add" type="text" class="form-control" placeholder="Voornaam..." v-model="courierAdd.courier.firstName">
                  </div>
                </div>
                <div class="col-12 col-lg-6">
                  <div class="form-group">
                    <label for="courier-last-name-field-add">Achternaam</label>
                    <input id="courier-last-name-field-add" type="text" class="form-control" placeholder="Achternaam..." v-model="courierAdd.courier.lastName">
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-lg-6">
                  <div class="form-group">
                    <label for="courier-email-field-add">E-mailadres</label>
                    <input id="courier-email-field-add" type="email" class="form-control" placeholder="E-mailadres..." v-model="courierAdd.courier.email">
                  </div>
                </div>
              </div>
              <div class="info-alert info-alert-header" v-if="emailExists">
                <div class="image">
                  <img src="~@/assets/img/icon-info.svg" alt="info">
                </div>
                <div class="text">
                  <p>Er is al een account met dit e-mailadres. Kies een ander e-mailadres.</p>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-lg-6">
                  <div class="form-group">
                    <label for="courier-phone-field-add">Telefoonnummer</label>
                    <input id="courier-phone-field-add" type="text" class="form-control" placeholder="Telefoonnummer..." v-model="courierAdd.courier.phone">
                  </div>
                </div>
              </div>
            </div>
            <div class="inner">
              <h4 class="title">Voertuig en frequent gereden routes</h4>
              <div class="row">
                <div class="col-12 col-lg-6">
                  <div class="form-group">
                    <label for="courier-number-plate-field-add" class="label-required">Kenteken</label>
                    <input id="courier-number-plate-field-add" type="text" minlength="6" maxlength="8" class="form-control" placeholder="Kenteken..." v-model="courierAdd.courier.numberPlate" @change="setCar(courierAdd.courier)" required>
                  </div>
                  <div class="result" v-if="courierAdd.courier.car">
                    <span>{{ courierAdd.courier.car }}</span>
                  </div>
                </div>
                <div class="col-12 col-lg-6">
                  <div class="form-group">
                    <label for="courier-tailgate-field-add" class="label-required">Heb je een laadklep?</label>
                    <select id="courier-tailgate-field-add" class="form-control" v-model="courierAdd.courier.tailgate" required>
                      <option :value="true">Ja</option>
                      <option :value="false">Nee</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <div class="form-group">
                    <label class="label-required">Vrije laadruimte voertuig</label>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-lg-4">
                  <div class="form-group">
                    <input type="number" class="form-control" placeholder="Lengte in cm" v-model="courierAdd.courier.cargoSpaceLength" required>
                  </div>
                </div>
                <div class="col-12 col-lg-4">
                  <div class="form-group">
                    <input type="number" class="form-control" placeholder="Breedte in cm" v-model="courierAdd.courier.cargoSpaceWidth" required>
                  </div>
                </div>
                <div class="col-12 col-lg-4">
                  <div class="form-group">
                    <input type="number" class="form-control" placeholder="Hoogte in cm" v-model="courierAdd.courier.cargoSpaceHeight" required>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <div class="form-group">
                    <label for="courier-routes-field-add">Wat zijn frequent gereden routes?</label>
                    <input id="courier-routes-field-add" type="text" class="form-control" v-model="courierAdd.courier.routes">
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <div class="form-group">
                    <label for="courier-note-field-add">Opmerkingen</label>
                    <textarea id="courier-note-field-add" class="form-control" v-model="courierAdd.courier.note"></textarea>
                  </div>
                </div>
              </div>
            </div>
            <button type="submit" class="btn-new btn-primary float-right" data-dismiss="modal" @click="addCourier">Koerier aanmaken</button>
          </div>
        </form>
      </div>
    </div>

    <!-- edit courier modal  -->

    <div class="modal fade" id="courierDetailModal" tabindex="-1" role="dialog" aria-labelledby="courierDetailModal" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <form class="modal-content" ref="formCourierEdit" v-on:submit.prevent>
          <div class="modal-header">
            <h3 class="modal-title" id="courierDetailModalTitle">{{ courierEdit.courier.companyName }}</h3>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M17 1L9 9M1 17L9 9M9 9L1 1L17 17" stroke="#B8BBC2"/>
              </svg>
            </button>
          </div>
          <div class="modal-body">
            <div class="overview-content-container" v-if="courierEdit.courier.status !== 'new'">
              <div class="row">
                <div class="col-12 col-lg-6">
                  <div class="overview-content-outer">
                    <div class="icon">
                      <svg width="45" height="44" viewBox="0 0 45 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="0.5" width="44" height="44" rx="22" fill="#25A268"/>
                        <path d="M11.4219 13.6845L16.014 9.07812L17.2321 10.3L14.7162 12.8237H22.555C24.7358 12.824 26.8672 13.4729 28.6795 14.6876C30.4921 15.9025 31.904 17.6287 32.7375 19.6472C33.571 21.6656 33.789 23.8863 33.3642 26.0287C32.9393 28.1711 31.8906 30.1398 30.3495 31.6856C28.8085 33.2314 26.8443 34.2849 24.705 34.7117C22.5657 35.1386 20.3483 34.9194 18.3335 34.0823C16.3187 33.2452 14.5977 31.828 13.3873 30.0109C12.1769 28.1938 11.5313 26.0582 11.5313 23.874V23.6304H13.26V23.874C13.26 25.7203 13.8058 27.5247 14.8278 29.0589C15.8497 30.593 17.3016 31.7878 18.999 32.4931C20.6965 33.1984 22.5639 33.3828 24.3654 33.0234C26.167 32.6639 27.8227 31.7765 29.1228 30.4723C30.423 29.1681 31.3091 27.5057 31.6682 25.695C32.0273 23.8843 31.8429 22.0075 31.1387 20.3022C30.4346 18.597 29.2426 17.1405 27.7143 16.1162C26.1866 15.0922 24.3912 14.5459 22.555 14.5456H14.7166L17.2321 17.069L16.014 18.2909L11.4219 13.6845Z" fill="white"/>
                        <path d="M20.6627 25.6893V19.5971H22.3915V23.9674H26.6112V25.6893H20.6627Z" fill="white"/>
                      </svg>
                    </div>
                    <div class="text">
                      <p class="smaller">Transporthistorie</p>
                      <p class="unique-font">{{ courierEdit.transports[0]['count'] + ' ' + (courierEdit.transports[0]['count'] === 1 ? 'transport' : 'transporten') }}</p>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-lg-6">
                  <div class="overview-content-outer">
                    <div class="icon">
                      <svg width="45" height="44" viewBox="0 0 45 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="0.5" width="44" height="44" rx="22" fill="#25A268"/>
                        <rect x="12.0469" y="13.6367" width="20.9" height="18.8112" stroke="white" stroke-width="1.5"/>
                        <path d="M18.3164 11.5508L18.3164 16.9851" stroke="white" stroke-width="1.5" stroke-linejoin="round"/>
                        <path d="M26.6797 11.5508L26.6797 16.9851" stroke="white" stroke-width="1.5" stroke-linejoin="round"/>
                        <path d="M12.0469 20.7461H32.9469" stroke="white" stroke-width="1.5" stroke-linejoin="round"/>
                      </svg>
                    </div>
                    <div class="text">
                      <p class="smaller">Koerier sinds</p>
                      <p class="unique-font">{{ formatDateFull(courierEdit.created) }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="inner" v-if="courierEdit.courier.status !== 'new'">
              <h4 class="title">Status</h4>
              <div class="row">
                <div class="col-12">
                  <div class="form-group">
                    <label for="courier-status-field-edit" class="label-required">Status account</label>
                    <select id="courier-status-field-edit" class="form-control" v-model="courierEdit.courier.status">
                      <option value="active">Actief</option>
                      <option value="inactive">Inactief</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div class="inner">
              <h4 class="title">Bedrijfsgegevens</h4>
              <div class="row">
                <div class="col-12 col-lg-6">
                  <div class="form-group">
                    <label for="courier-company-name-field-edit" class="label-required">Bedrijfsnaam</label>
                    <input id="courier-company-name-field-edit" type="text" class="form-control" placeholder="Bedrijfsnaam..." v-model="courierEdit.courier.companyName" required>
                  </div>
                </div>
                <div class="col-12 col-lg-6">
                  <div class="form-group">
                    <label for="courier-coc-field-edit" class="label-required">KvK-nummer</label>
                    <input id="courier-coc-field-edit" type="text" class="form-control" placeholder="KvK-nummer" v-model="courierEdit.courier.coc" required>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-lg-6">
                  <div class="form-group">
                    <label for="courier-postal-code-field-edit" class="label-required">Postcode</label>
                    <input id="courier-postal-code-field-edit" type="text" class="form-control" placeholder="Postcode..." v-model="courierEdit.courier.postalCode" @change="setAddress(courierEdit.courier)" required>
                  </div>
                </div>
                <div class="col-12 col-lg-3">
                  <div class="form-group">
                    <label for="courier-house-number-field-edit" class="label-required">Huisnr.</label>
                    <input id="courier-house-number-field-edit" type="text" class="form-control" placeholder="Huisnr..." v-model="courierEdit.courier.houseNumber" @change="setAddress(courierEdit.courier)" required>
                  </div>
                </div>
                <div class="col-12 col-lg-3">
                  <div class="form-group">
                    <label for="courier-house-number-addition-field-edit">Toev.</label>
                    <input id="courier-house-number-addition-field-edit" type="text" class="form-control" placeholder="Toev..." v-model="courierEdit.courier.houseNumberAddition" @change="setAddress(courierEdit.courier)">
                  </div>
                </div>
              </div>
              <div class="result result-address" v-if="courierEdit.courier.address">
                <img src="~@/assets/img/icon-marker.svg" alt="marker">
                <p class="smaller">{{ courierEdit.courier.address }}</p>
              </div>
            </div>
            <div class="inner">
              <h4 class="title">Contactpersoon</h4>
              <div class="row">
                <div class="col-12 col-lg-6">
                  <div class="form-group">
                    <label for="courier-first-name-field-edit">Voornaam</label>
                    <input id="courier-first-name-field-edit" type="text" class="form-control" placeholder="Voornaam..." v-model="courierEdit.courier.firstName">
                  </div>
                </div>
                <div class="col-12 col-lg-6">
                  <div class="form-group">
                    <label for="courier-last-name-field-edit">Achternaam</label>
                    <input id="courier-last-name-field-edit" type="text" class="form-control" placeholder="Achternaam..." v-model="courierEdit.courier.lastName">
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-lg-6">
                  <div class="form-group">
                    <label for="courier-email-field-edit">E-mailadres</label>
                    <input id="courier-email-field-edit" type="email" class="form-control" placeholder="E-mailadres..." v-model="courierEdit.courier.email">
                  </div>
                </div>
              </div>
              <div class="info-alert info-alert-header" v-if="emailExists">
                <div class="image">
                  <img src="~@/assets/img/icon-info.svg" alt="info">
                </div>
                <div class="text">
                  <p>Er is al een account met dit e-mailadres. Kies een ander e-mailadres.</p>
                </div>
              </div>
              <a href="#" class="reset-link" data-dismiss="modal" @click="recoverPassword" v-if="courierEdit.user.id && !emailExists">Wachtwoord reset mail versturen</a>
              <div class="row">
                <div class="col-12 col-lg-6">
                  <div class="form-group">
                    <label for="courier-phone-field-edit">Telefoonnummer</label>
                    <input id="courier-phone-field-edit" type="text" class="form-control" placeholder="Telefoonnummer..." v-model="courierEdit.courier.phone">
                  </div>
                </div>
              </div>
            </div>
            <div class="inner">
              <h4 class="title">Voertuig en frequent gereden routes</h4>
              <div class="row">
                <div class="col-12 col-lg-6">
                  <div class="form-group">
                    <label for="courier-number-plate-field-edit" class="label-required">Kenteken</label>
                    <input id="courier-number-plate-field-edit" type="text" minlength="6" maxlength="8" class="form-control" placeholder="Kenteken..." v-model="courierEdit.courier.numberPlate" @change="setCar(courierEdit.courier)" required>
                  </div>
                  <div class="result" v-if="courierEdit.courier.car">
                    <span>{{ courierEdit.courier.car }}</span>
                  </div>
                </div>
                <div class="col-12 col-lg-6">
                  <div class="form-group">
                    <label for="courier-tailgate-field-edit" class="label-required">Heb je een laadklep?</label>
                    <select id="courier-tailgate-field-edit" class="form-control" v-model="courierEdit.courier.tailgate" required>
                      <option :value="true">Ja</option>
                      <option :value="false">Nee</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <div class="form-group">
                    <label class="label-required">Vrije laadruimte voertuig</label>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-lg-4">
                  <div class="form-group">
                    <input type="number" class="form-control" placeholder="Lengte in cm" v-model="courierEdit.courier.cargoSpaceLength" required>
                  </div>
                </div>
                <div class="col-12 col-lg-4">
                  <div class="form-group">
                    <input type="number" class="form-control" placeholder="Breedte in cm" v-model="courierEdit.courier.cargoSpaceWidth" required>
                  </div>
                </div>
                <div class="col-12 col-lg-4">
                  <div class="form-group">
                    <input type="number" class="form-control" placeholder="Hoogte in cm" v-model="courierEdit.courier.cargoSpaceHeight" required>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <div class="form-group">
                    <label for="courier-routes-field-edit">Wat zijn frequent gereden routes?</label>
                    <input id="courier-routes-field-edit" type="text" class="form-control" v-model="courierEdit.courier.routes">
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <div class="form-group">
                    <label for="courier-note-field-edit">Opmerkingen</label>
                    <textarea id="courier-note-field-edit" class="form-control" v-model="courierEdit.courier.note"></textarea>
                  </div>
                </div>
              </div>
            </div>
            <template v-if="!courierEdit.transports[0]['count']">
              <button type="submit" class="btn-new btn-primary float-right" data-dismiss="modal" @click="updateCourier">{{ courierEdit.user.id ? 'Wijzigingen opslaan' : 'Opslaan en account aanmaken' }}</button>
              <div class="remove-section">
                <div class="form-group form-check special-check special-check-no-icon simple-checks">
                  <input type="checkbox" id="confirm-delete-check" name="confirmDelete" class="form-check-input" :value="true" v-model="confirmDelete">
                  <label for="confirm-delete-check" class="form-check-label">Ik weet zeker dat ik deze koerier wil verwijderen</label>
                </div>
                <button class="btn-new btn-remove" data-dismiss="modal" @click="deleteCourier" :disabled="!confirmDelete">Verwijder koerier</button>
              </div>
            </template>
          </div>
        </form>
      </div>
    </div>
  </div>

  <!-- alert -->

  <div class="alerts alerts-success" role="alert" v-if="showPasswordAlert">
    <div class="image">
      <img src="~@/assets/img/check-white-round.svg" width="27" height="27" alt="check">
    </div>
    <div class="text">
      <p class="bold extra-margin">Wachtwoord opnieuw ingesteld</p>
      <p class="smaller">Er is een e-mail gestuurd naar {{ courierEdit.courier.email }} met instructies.</p>
    </div>
  </div>
</template>

<script>

import { formatDateFull } from '@/utils/format';
import validateForm from '@/utils/form-validate';

export default {
  props: ['user'],
  data () {
    return {
      loading: null,
      abortController: new AbortController,
      abortCount: 0,
      showPasswordAlert: false,
      couriers: [],
      courierDefault: {
        'user': {
          'id': ''
        },
        'courier': {
          'status': '',
          'companyName': '',
          'coc': '',
          'postalCode': '',
          'houseNumber': '',
          'houseNumberAddition': '',
          'street': '',
          'city': '',
          'address': '',
          'firstName': '',
          'lastName': '',
          'email': '',
          'phone': '',
          'numberPlate': '',
          'car': '',
          'tailgate': false,
          'cargoSpaceLength': '',
          'cargoSpaceWidth': '',
          'cargoSpaceHeight': '',
          'routes': '',
          'note': '',
          'validAddress': true
        },
        'transports': [
          {
            'count': 0
          }
        ],
        'created': ''
      },
      courierAdd: {},
      courierEdit: {},
      confirmDelete: false,
      filter: {
        'status': '',
        'search': ''
      },
      emailExists: false,
      emailInit: ''
    }
  },
  methods: {
    formatDateFull,
    initCourierAdd () {
      this.courierAdd = JSON.parse(JSON.stringify(this.courierDefault));
    },
    initCourierEdit(courier) {
      this.confirmDelete = false;
      this.courierEdit = JSON.parse(JSON.stringify(courier));
      this.emailInit = JSON.parse(JSON.stringify(courier.courier.email));

      if (courier.courier.status === 'new') {
        this.setCar(this.courierEdit.courier);
        this.checkUser(this.courierEdit.courier.email);
      }
    },
    setAddress(courier) {
      let validAddress = false;

      if (courier.postalCode
        && courier.houseNumber
      ) {
        courier.postalCode = courier.postalCode.toUpperCase().replace(/\s/g, '');

        fetch('/api/orders/validate-postal-code/' + courier.postalCode + '/' + courier.houseNumber)
          .then(response => response.ok ? response.json() : [])
          .then(data => {

            if (data['valid']) {
              validAddress = true;
              courier.validAddress = true;

              courier.street = data['street'];
              courier.city = data['city'];
              courier.address =
                courier.street
                + ' '
                + courier.houseNumber
                + courier.houseNumberAddition
                + ' in ' + courier.city;
            }
          })
      }

      if (!validAddress) {
        courier.street = '';
        courier.city = '';
        courier.address = '';
        courier.validAddress = false;
      }
    },
    setCar(courier) {
      courier.numberPlate = courier.numberPlate.toUpperCase().replaceAll('-', '');
      courier.car = '';

      if (/^[A-Z0-9]{6}$/.test(courier.numberPlate)) {

        fetch('/api/couriers/vehicles/search/' + courier.numberPlate)
          .then(response => response.ok ? response.text() : '')
          .then(data => {

            if (data) {
              courier.car = data;
            } else {
              courier.numberPlate = '';
            }
          })
      }
    },
    async getCouriers () {

      if (this.loading) {
        this.abortController.abort();
        this.abortController = new AbortController();
      }

      this.loading = true;

      try {

        await fetch('/api/couriers?status=' + this.filter.status + '&search=' + encodeURIComponent(this.filter.search),
          { signal: this.abortController.signal }
        )
          .then(response => response.ok ? response.json() : [])
          .then(data => {
            this.couriers = data;
            this.loading = false;
          })
      } catch (e) {
        this.abortCount++;
      }
    },
    addCourier () {

      fetch('/api/couriers', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(this.courierAdd)
      })
        .then(response => {

          if (response.ok) {
            this.getCouriers();
          }
        })
    },
    updateCourier () {

      fetch('/api/couriers/' + this.courierEdit.id, {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(this.courierEdit)
      })
        .then(response => {

          if (response.ok) {
            this.getCouriers();
          }
        })
    },
    deleteCourier () {
      this.confirmDelete = false;

      fetch('/api/couriers/' + this.courierEdit.id, {
        method: 'DELETE'
      })
        .then(response => {

          if (response.ok) {
            this.getCouriers();
          }
        })
    },
    checkUser(email) {

      fetch('/api/users/check/' + encodeURIComponent(email))
        .then(response => response.ok ? response.json() : false)
        .then(data => {
          this.emailExists = data;
        })
    },
    recoverPassword () {

      fetch('/api/password-recover', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 'email': this.courierEdit.courier.email })
      })
        .then(response => {

          if (response.ok) {
            this.showPasswordAlert = true;

            setTimeout(() => {
              this.showPasswordAlert = false;
            }, 3000);
          }
        })
    }
  },
  watch: {
    filter: {
      deep: true,
      handler: function () {
        this.getCouriers();
      }
    },
    courierAdd: {
      deep: true,
      handler: function () {
        validateForm(this.$refs['formCourierAdd'], this.courierAdd.courier.car !== '' && this.courierAdd.courier.validAddress);
      }
    },
    courierEdit: {
      deep: true,
      handler: function () {
        validateForm(this.$refs['formCourierEdit'], this.courierEdit.courier.car !== '' && this.courierEdit.courier.validAddress);
      }
    },
    'courierAdd.courier.email': {
      handler: function () {
        this.emailExists = false;

        if (this.courierAdd.courier.email
          && document.querySelector('#courier-email-field-add:invalid') === null
        ) {
          this.checkUser(this.courierAdd.courier.email);
        }
      }
    },
    'courierEdit.courier.email': {
      handler: function () {
        this.emailExists = false;

        if (this.courierEdit.courier.email
          && this.courierEdit.courier.email !== this.emailInit
          && document.querySelector('#courier-email-field-edit:invalid') === null
        ) {
          this.checkUser(this.courierEdit.courier.email);
        }
      }
    }
  },
  created () {
    this.courierAdd = JSON.parse(JSON.stringify(this.courierDefault));
    this.courierEdit = JSON.parse(JSON.stringify(this.courierDefault));

    this.getCouriers();
  }
}

</script>
