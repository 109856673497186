<template>
  <div class="container-outer overwrite">
    <main id="normal">
      <section class="standard-layout transport-history transport-history-admin transports-history-admin-extra headings-reset">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <router-link to="/dashboard/admin-index" class="back-link">Naar dashboard</router-link>
              <h1 class="maintitle">Transporten</h1>
              <div class="overview-content-container d-lg-none">
                <div class="row">
                  <div class="col-12 col-lg-4">
                    <div class="overview-content-outer">
                      <div class="icon">
                        <img src="~@/assets/img/icon-euro.svg" width="28" height="28" alt="euro">
                      </div>
                      <div class="text">
                        <p class="smaller">Omzet laatste 30 dagen</p>
                        <p class="unique-font">&euro; {{ formatPrice(orderAmounts.month) }}</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-lg-4">
                    <div class="overview-content-outer">
                      <div class="icon">
                        <img src="~@/assets/img/icon-euro.svg" width="28" height="28" alt="euro">
                      </div>
                      <div class="text">
                        <p class="smaller">Omzet laatste 90 dagen</p>
                        <p class="unique-font">&euro; {{ formatPrice(orderAmounts.quarter) }}</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-lg-4">
                    <div class="overview-content-outer">
                      <div class="icon">
                        <img src="~@/assets/img/icon-euro.svg" width="28" height="28" alt="euro">
                      </div>
                      <div class="text">
                        <p class="smaller">Omzet laatste 12 maanden</p>
                        <p class="unique-font">&euro; {{ formatPrice(orderAmounts.year) }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group form-group-extra-space form-group-border-bottom d-none d-lg-block">
                <div class="icon-container icon-container-left icon-container-right search-container" :class="{ 'search-active': filter.search }">
                  <img src="@/assets/img/icon-search.svg" alt="search">
                  <input type="text" class="form-control" placeholder="Zoeken..." v-model="filter.search">
                  <img class="search-img" src="@/assets/img/search-remove.svg" alt="remove" v-if="filter.search" @click="filter.search = ''">
                </div>
              </div>
              <div class="form-group form-group-extra-space d-none d-lg-block">
                <select class="form-control select-form-group" v-model="filter.status">
                  <option value="">Alle transporten tonen</option>
                  <option value="open">Openstaande transporten</option>
                  <option value="accepted">Geaccepteerde transporten</option>
                  <option value="delivered">Afgeleverde transporten</option>
                </select>
              </div>
            </div>
            <div class="col-12 d-lg-none">
              <div class="form-group form-group-extra-space">
                <select class="form-control select-form-group" v-model="filter.status">
                  <option value="">Alle transporten tonen</option>
                  <option value="open">Openstaande transporten</option>
                  <option value="accepted">Geaccepteerde transporten</option>
                  <option value="delivered">Afgeleverde transporten</option>
                </select>
              </div>
              <div class="form-group form-group-extra-space form-group-border-bottom">
                <div class="icon-container icon-container-left icon-container-right search-container">
                  <img src="@/assets/img/icon-search.svg" alt="search">
                  <input type="text" class="form-control" placeholder="Zoeken..." v-model="filter.search">
                  <img class="search-img" src="@/assets/img/search-remove.svg" alt="remove" v-if="filter.search" @click="filter.search = ''">
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="overview-content-container d-none d-lg-block">
                <div class="row">
                  <div class="col-12 col-lg-4">
                    <div class="overview-content-outer">
                      <div class="icon">
                        <img src="~@/assets/img/icon-euro.svg" width="44" height="44" alt="euro">
                      </div>
                      <div class="text">
                        <p class="smaller">Omzet laatste 30 dagen</p>
                        <p class="unique-font">&euro; {{ formatPrice(orderAmounts.month) }}</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-lg-4">
                    <div class="overview-content-outer">
                      <div class="icon">
                        <img src="~@/assets/img/icon-euro.svg" width="44" height="44" alt="euro">
                      </div>
                      <div class="text">
                        <p class="smaller">Omzet laatste 90 dagen</p>
                        <p class="unique-font">&euro; {{ formatPrice(orderAmounts.quarter) }}</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-lg-4">
                    <div class="overview-content-outer">
                      <div class="icon">
                        <img src="~@/assets/img/icon-euro.svg" width="44" height="44" alt="euro">
                      </div>
                      <div class="text">
                        <p class="smaller">Omzet laatste 12 maanden</p>
                        <p class="unique-font">&euro; {{ formatPrice(orderAmounts.year) }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row" v-if="loading" id="extra-margin">
            <div class="col-12 text-center">
              <SpinnerBigIcon/>
            </div>
          </div>

          <div class="row extra-margin-top" v-if="!loading && !orders.length">
            <div class="col-12">
              <div class="empty">
                <div class="content-left">
                  <h5 class="">Geen transporten gevonden...</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="row" v-if="!loading && orders.length">
            <div class="col-12">
              <div class="table-responsive">
                <table class="table table-striped table-custom table-unique">
                  <thead>
                    <tr>
                      <th>Ophalen</th>
                      <th>Afleveren</th>
                      <th>Status</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(order, orderIndex) in orders" :key="order.id">
                      <template v-if="orderIndex === 100">
                        <td colspan="5">
                          <p class="bold">Meer dan 100 transporten - beperk het resultaat door te filteren</p>
                        </td>
                      </template>
                      <template v-else>
                        <td>
                          <p class="with-icon">{{ order.pickupStreet }} {{ order.pickupHouseNumber }}{{ order.pickupHouseNumberAddition }}<br />
                            {{ order.pickupPostalCode }} {{ order.pickupCity }}</p>
                          <p class="smaller">{{ formatDateFull(order.dateDb) }}</p>
                          <p class="bold">{{ order.pickupTime === 'custom' ? formatCustomTimePeriod(order.pickupTimeCustom, 'pickup') : formatDayPeriod(order.pickupTime) }}</p>
                        </td>
                        <td>
                          <p class="with-icon">{{ order.deliveryStreet }} {{ order.deliveryHouseNumber }}{{ order.deliveryHouseNumberAddition }}<br />
                            {{ order.deliveryPostalCode }} {{ order.deliveryCity }}</p>
                          <p class="smaller">{{ formatDateFull(order.dateDb) }}</p>
                          <p class="bold">{{ order.deliveryTime === 'custom' ? formatCustomTimePeriod(order.deliveryTimeCustom, 'delivery') : formatDayPeriod(order.deliveryTime) }}</p>
                        </td>
                        <td>
                          <div class="label" :class="order.transport.id ? 'label-green': 'label-gray'" v-if="order.transport.status !== 'delivered'">{{ order.transport.id ? 'Aangenomen' : 'Openstaand' }}</div>
                          <div class="label label-green label-with-icon" v-if="order.transport.status === 'delivered'">
                            <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <circle cx="8.5" cy="9" r="7.5" stroke="white" stroke-width="1.25"/>
                              <path d="M4.60156 8.85203L7.4518 11.702L12.5518 6.60156" stroke="white" stroke-width="1.25"/>
                            </svg>
                            Afgeleverd
                          </div>
                        </td>
                        <td class="with-btn">
                          <button class="btn btn-black btn-small" data-toggle="modal" data-target="#transportDetailModal" @click="orderDetails = JSON.parse(JSON.stringify(order))">Toon details</button>
                          <button class="btn btn-outline btn-small" @click="updatePaymentStatus(order.id)" v-if="order.setPaymentStatus">Markeer als betaald</button>
                        </td>
                      </template>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>

    <!-- transport detail modal  -->

    <div class="modal fade" id="transportDetailModal" tabindex="-1" role="dialog" aria-labelledby="transportDetailModal" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h3 class="modal-title" id="transportDetailModalTitle">Transport van {{ orderDetails.itemsCount }} {{ orderDetails.itemsCount === 1 ? 'item' : 'items' }}</h3>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M17 1L9 9M1 17L9 9M9 9L1 1L17 17" stroke="#B8BBC2"/>
              </svg>
            </button>
          </div>
          <div class="modal-body">
            <div class="inner transport-detail" :class="{ 'extra-margin': orderDetails.transport.id }">
              <p>
                <i v-if="orderDetails.transport.id">Transport # {{ orderDetails.id }} aangenomen door <strong>{{ orderDetails.transport.courierName || 'onbekend' }}</strong> op {{ formatDateTimeLocale(orderDetails.transport.created) }}</i>
                <i v-else>Transport # {{ orderDetails.id }}</i>
              </p>
              <button class="continue-link" @click="$router.push('/dashboard/admin-couriers')" data-dismiss="modal" v-if="orderDetails.transport.id"></button>
            </div>
            <div class="inner" v-if="orderDetails.transport.id">
              <h4 class="title">Status</h4>
              <div class="row">
                <div class="col-12">
                  <div class="form-group">
                    <label class="label-required">Status transport</label>
                    <select class="form-control" v-model="orderDetails.transport.status">
                      <option value="accepted">Aangenomen</option>
                      <option value="delivered">Afgeleverd</option>
                      <option value="cancelled">Geannuleerd</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <div class="form-group">
                    <label>Notities</label>
                    <textarea class="form-control" v-model="orderDetails.transport.note"></textarea>
                  </div>
                </div>
              </div>
              <button class="btn-new btn-primary float-right" data-dismiss="modal" aria-label="Close" @click="updateTransport">Wijzigingen opslaan</button>
            </div>
            <div class="transport-items">
              <div class="row">
                <div class="col-12 col-lg-4">
                  <div class="transport-item-inner first-item">
                    <img src="~@/assets/img/icon-euro.svg" width="28" height="29" alt="euro">
                    <p class="inner-title">Omzet</p>
                    <div class="details">
                      <p class="bold">&euro; {{ formatPrice(orderDetails.priceSubtotal) }}</p>
                      <span>excl. btw</span>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-lg-4">
                  <div class="transport-item-inner">
                    <img src="~@/assets/img/icon-marker-green.svg" width="28" height="29" alt="marker">
                    <p class="inner-title">Totale afstand</p>
                    <div class="details">
                      <p class="bold">{{ orderDetails.distance }}</p>
                      <span>km</span>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-lg-4">
                  <div class="transport-item-inner last-item">
                    <img src="~@/assets/img/icon-volume.svg" width="28" height="29" alt="volume">
                    <p class="inner-title">Totaal volume</p>
                    <div class="details">
                      <p class="bold">{{ calculateVolumeItems(orderDetails.items) || '&mdash;' }}</p>
                      <span>m&#179;</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <h4 class="subtitle">Route</h4>
            <div class="row">
              <div class="col-12 col-lg-6 first-col">
                <div class="route-inner">
                  <p class="smaller">{{ formatDateFull(orderDetails.dateDb) }}</p>
                  <p class="bold">{{ orderDetails.pickupTime === 'custom' ? formatCustomTimePeriod(orderDetails.pickupTimeCustom, 'pickup') : formatDayPeriod(orderDetails.pickupTime) }}</p>
                  <div class="route-location">
                    <img src="~@/assets/img/icon-marker.svg" width="16" height="19" alt="marker">
                    <p class="smaller">{{ orderDetails.pickupAddress }}</p>
                  </div>
                  <div class="half">
                    <p class="smaller semibold">Type gebouw</p>
                    <p class="smaller">{{ orderDetails.pickupBuildingType ? showLabel(orderDetails.pickupBuildingType, buildingTypes) : 'Onbekend' }}</p>
                  </div>
                  <div class="half">
                    <p class="smaller semibold">Verdieping</p>
                    <p class="smaller">{{ orderDetails.pickupFloor ? showLabel(orderDetails.pickupFloor, floorTypes) : 'Begane grond' }}</p>
                  </div>
                  <div class="half">
                    <p class="smaller semibold">Lift aanwezig?</p>
                    <p class="smaller">{{ orderDetails.deliveryType !== 'custom' || orderDetails.pickupFloor === 'ground floor' ? 'Onbekend' : (orderDetails.pickupElevator ? 'Ja' : 'Nee') }}</p>
                  </div>
                  <div class="half">
                    <p class="smaller semibold">Aantal koeriers</p>
                    <div class="half-inner">
                      <img src="~@/assets/img/icon-courier.svg" alt="courier">
                      <img src="~@/assets/img/icon-courier.svg" alt="courier" v-if="orderDetails.help">
                    </div>
                  </div>
                  <div class="half border-top">
                    <p class="smaller semibold">{{ orderDetails.customer.companyName || 'Particulier' }}</p>
                    <p class="smaller"></p>
                  </div>
                  <div class="half">
                    <p class="smaller">{{ orderDetails.customer.fullName }}</p>
                    <p class="smaller">{{ orderDetails.pickupPhone || orderDetails.customer.phone }}</p>
                  </div>
                </div>
              </div>
              <div class="col-12 d-lg-none text-center">
                <svg class="d-lg-none arrow-special" width="19" height="10" viewBox="0 0 19 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0.5 1.3469L1.74093 0L9.5 7.46L17.2591 0L18.5 1.3469L9.5 10L0.5 1.3469Z" fill="#282A2F"/>
                </svg>
                <svg class="d-none d-lg-block arrow-special" width="10" height="18" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1.3469 18L-5.42428e-08 16.7591L7.46 9L-7.32562e-07 1.24093L1.3469 -5.88748e-08L10 9L1.3469 18Z" fill="#282A2F"/>
                </svg>
              </div>
              <div class="col-12 col-lg-6 last-col">
                <div class="route-inner">
                  <p class="smaller">{{ formatDateFull(orderDetails.dateDb) }}</p>
                  <p class="bold">{{ orderDetails.deliveryTime === 'custom' ? formatCustomTimePeriod(orderDetails.deliveryTimeCustom, 'delivery') : formatDayPeriod(orderDetails.deliveryTime) }}</p>
                  <div class="route-location">
                    <img src="~@/assets/img/icon-marker.svg" width="16" height="19" alt="marker">
                    <p class="smaller">{{ orderDetails.deliveryAddress }}</p>
                  </div>
                  <div class="half">
                    <p class="smaller semibold">Type gebouw</p>
                    <p class="smaller">{{ orderDetails.deliveryBuildingType ? showLabel(orderDetails.deliveryBuildingType, buildingTypes) : 'Onbekend' }}</p>
                  </div>
                  <div class="half">
                    <p class="smaller semibold">Verdieping</p>
                    <p class="smaller">{{ orderDetails.deliveryFloor ? showLabel(orderDetails.deliveryFloor, floorTypes) : 'Begane grond' }}</p>
                  </div>
                  <div class="half">
                    <p class="smaller semibold">Lift aanwezig?</p>
                    <p class="smaller">{{ orderDetails.deliveryType !== 'custom' || orderDetails.deliveryFloor === 'ground floor' ? 'Onbekend' : (orderDetails.deliveryElevator ? 'Ja' : 'Nee') }}</p>
                  </div>
                  <div class="half">
                    <p class="smaller semibold">Aantal koeriers</p>
                    <div class="half-inner">
                      <img src="~@/assets/img/icon-courier.svg" alt="courier">
                      <img src="~@/assets/img/icon-courier.svg" alt="courier" v-if="orderDetails.help">
                    </div>
                  </div>
                  <div class="half border-top">
                    <p class="smaller semibold">{{ orderDetails.customer.companyName || 'Particulier' }}</p>
                    <p class="smaller"></p>
                  </div>
                  <div class="half">
                    <p class="smaller">{{ orderDetails.customer.fullName }}</p>
                    <p class="smaller">{{ orderDetails.deliveryPhone || orderDetails.customer.phone }}</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="information" v-if="orderDetails.note">
              <p class="bold">
                <img src="~@/assets/img/icon-warning.svg" alt="warning">
                Opmerking van aanvrager
              </p>
              <p class="smaller">{{ orderDetails.note }}</p>
            </div>
            <template v-if="orderDetails.items.length">
              <h4 class="subtitle">Items</h4>
              <div class="table-outer">
                <div class="table-responsive">
                  <table class="table">
                    <thead>
                      <tr>
                        <th>Aantal</th>
                        <th>Item</th>
                        <th>Lengte</th>
                        <th>Breedte</th>
                        <th>Hoogte</th>
                        <th>Gewicht</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="item in orderDetails.items" :key="item.id">
                        <td>{{ item.amount }}</td>
                        <td class="with-icon" :class="itemTypes.find(itemType => itemType.value === item.type)['class']" v-if="!item.typeOther">
                          <span>{{ itemTypes.find(itemType => itemType.value === item.type)['label'] }}</span>
                        </td>
                        <td class="with-icon different" v-if="item.typeOther">
                          <span>Anders: {{ item.typeOther }}</span>
                        </td>
                        <td>{{ item.length }} cm</td>
                        <td>{{ item.width }} cm</td>
                        <td>{{ item.height }} cm</td>
                        <td>{{ item.weightClass }} kg</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>

import { showLabel, formatPrice, formatDateFull, formatDayPeriod, formatDateTimeLocale, formatCustomTimePeriod } from '@/utils/format';
import { calculateVolumeItems } from '@/utils/calculate';
import SpinnerBigIcon from '@/components/common/SpinnerBigIcon';
import '@/assets/js/itemTypes';
import itemTypes from '@/assets/js/itemTypes';

export default {
  components: {
    SpinnerBigIcon
  },
  props: ['user'],
  data () {
    return {
      loading: null,
      abortController: new AbortController,
      abortCount: 0,
      itemTypes: itemTypes,
      floorTypes: require('@/assets/js/floorTypes.json'),
      buildingTypes: require('@/assets/js/buildingTypes.json'),
      orders: [],
      orderDetails: {
        'items': [],
        'customer': {},
        'transport': {}
      },
      orderAmounts: {
        'month': 0,
        'quarter': 0,
        'year': 0
      },
      filter: {
        'status': '',
        'search': ''
      }
    }
  },
  methods: {
    showLabel,
    formatPrice,
    formatDateFull,
    formatDayPeriod,
    formatDateTimeLocale,
    formatCustomTimePeriod,
    calculateVolumeItems,
    async getOrders () {

      if (this.loading) {
        this.abortController.abort();
        this.abortController = new AbortController();
      }

      this.loading = true;

      try {

        await fetch('/api/orders' + (this.filter.status ? '/' + this.filter.status : '') + '?search=' + encodeURIComponent(this.filter.search),
          { signal: this.abortController.signal }
        )
          .then(response => response.ok ? response.json() : [])
          .then(data => {
            this.orders = data;
            this.loading = false;
          })
      } catch (e) {
        this.abortCount++;
      }
    },
    getOrderAmounts () {
      const orderAmounts = this.orderAmounts;
      let
        iterations = 1,
        dummyAmount = 1;

      function increment () {
        orderAmounts.month = dummyAmount;
        orderAmounts.quarter = dummyAmount * 2.5;
        orderAmounts.year = dummyAmount * 10;

        let amountStep = 200 / (iterations / 11);
        dummyAmount += amountStep;
        iterations++;
      }

      let counter = setInterval(increment, 50);

      fetch('/api/orders/amounts')
        .then(response => response.ok ? response.json() : this.orderAmounts)
        .then(data => {
          clearInterval(counter);
          this.orderAmounts = data;
        })
    },
    updateTransport () {

      fetch('/api/transports/' + this.orderDetails.transport.id, {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(this.orderDetails.transport)
      })
        .then(response => {

          if (response.ok) {
            this.getOrders();
          }
        })
    },
    updatePaymentStatus(orderId) {
      this.loading = true;

      fetch('/api/orders/' + orderId + '/update-payment-status', {
        method: 'PATCH'
      })
        .then(() => {
          this.getOrders();
        })
    }
  },
  watch: {
    filter: {
      deep: true,
      handler: function () {
        this.getOrders();
      }
    }
  },
  created () {
    this.getOrders();
    this.getOrderAmounts();
  }
}

</script>
